@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
/*
*   CSS Input Vars =====================
*   --set-carousel-gap    
*
*/

.container {
  // padding-bottom: 72px;
}

.slider {
  display: flex;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  --gap: var(--set-carousel-gap, 24px);
  gap: var(--gap);
  scroll-padding: var(--page-margin);

  &__block {
    scroll-snap-align: start;
    width: 100%;

    &Centered {
      scroll-snap-align: center;
    }

    &:first-of-type {
      padding: 0 0 0 var(--page-margin);
    }

    &:last-of-type {
      padding: 0 var(--page-margin) 0 0;
    }

    & > div {
      height: 100%;
    }
  }
}
